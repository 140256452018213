import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppContext } from "../../context/AppContext";
import { fetchDashboardReport } from "../../services/HomeService";
import { toast, ToastContainer } from "material-react-toastify";
import {
  showCatchedExceptionOnToast,
  validateAnsShowServerException,
} from "../../utils/ExceptionUtils";
import { REPORT_D1T } from "../../constants/APIConstants";
import {
  DASHBOARD_D1T,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TIMEZONE_OFFSET,
} from "../../constants/Constants";
import {
  getCurrentDateYYYYMMDD,
  getDateFromString,
  getDayBySubtractingNumDays,
} from "../../utils/DateUtils";
import Loader from "../loader/Loader";
import CommonListView from "../commonListView/CommonListView";
import { getVPNDetectedReportDataGridColumns } from "./VPNDetectedReportDataGridColumns";
import DashboardDatepicker from "../shared/DashboardDatepicker";
import ResultsImage from "../../assets/images/search-results.png";
import GenericError from "../GenericError/GenericError";
import { useTheme } from "@emotion/react";

const noDataReasons = {
  title: "No data for your selected search date.",
  image: ResultsImage,
};

const VPNDetectedContainer = () => {
  const appContext = useContext(AppContext);
  const { dashboardStates } = appContext;
  const location = useLocation();
  const theme = useTheme();
  const { selectedUserApp, getUserReportByAPI } = appContext;
  const [showLoader, setShowLoader] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [selectedPage, setSelectedPage] = useState({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [dateFilter, setDateFilter] = useState(
    dashboardStates["date"] || {
      startDate: getCurrentDateYYYYMMDD(),
      endDate: getCurrentDateYYYYMMDD(),
    }
  );

  useEffect(() => {
    fetchVPNDetectedReportData();
  }, [selectedPage, location, dateFilter]);

  const fetchVPNDetectedReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_D1T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : DASHBOARD_D1T,
        appId: selectedUserApp.AppId,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
        ListView: "true",
        PageNumber: selectedPage.page,
        PageSize: selectedPage.pageSize,
        ReasonForFailure: 14,
      };
      setShowLoader(true);
      fetchDashboardReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setReportData(response);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const onRefreshData = (data, type) => {
    if (type === 1) {
      setDateFilter({
        ...dateFilter,
        startDate: data,
        endDate: data,
      });
    } else {
      setSelectedPage(data);
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontSize: "1.3rem",
          fontWeight: "500",
          marginBottom: "0.5rem",
          color: theme.palette.text.primary,
        }}
      >
        VPN Detected
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        <DashboardDatepicker
          onRefreshData={onRefreshData}
          type={1}
          defaultDate={getDateFromString(dateFilter.startDate)}
        />
      </Box>

      {reportData && reportData.Data.Results.length > 0 && (
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: "5px",
          }}
        >
          <CommonListView
            rows={reportData.Data.Results}
            columns={getVPNDetectedReportDataGridColumns(
              appContext.failedReasons,
              theme
            )}
            rowCount={reportData.Data.TotalRecords}
            pageSize={100}
            onRefreshData={(paginationModel) =>
              onRefreshData(paginationModel, 2)
            }
            getRowId={(row) => row.GeoReferenceId}
            // height={tabIndex === 1 ? "70vh" : "50vh"}
          />
        </Box>
      )}

      {reportData && reportData.Data.Results.length === 0 && (
        <GenericError data={noDataReasons} />
      )}

      {showLoader && <Loader />}
      <ToastContainer position="top-center" autoClose={3000} />
    </>
  );
};

export default VPNDetectedContainer;
