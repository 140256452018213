import { Link } from "react-router-dom";
import {
  getColorCodeByReasonCode,
  getLocatedNameByCode,
  getReasonsNameByCode,
} from "../../utils/CommonUtils";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";

export const getColumnsForTransactionsSummary = (summaryType, failedReasons, shiftColumn, theme) => {

  if (shiftColumn) {
    if (summaryType === "device") {
      return [
        {
          field: "ReasonForFailure",
          headerName: "Status",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <div
                  style={{
                    minWidth: "8px",
                    minHeight: "8px",
                    backgroundColor: getColorCodeByReasonCode(
                      params.row.ReasonForFailure,
                      failedReasons
                    ),
                    borderRadius: "4px",
                  }}
                ></div>
                {getReasonsNameByCode(
                  params.row.ReasonForFailure,
                  failedReasons
                )}
              </div>
            );
          },
          valueGetter: (params) => {
            return getReasonsNameByCode(params, failedReasons);
          },
        },
        {
          field: "AccountId",
          headerName: "Account ID",
          width: 250,
          display: "flex",
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/account/" + cellValues.row.AccountId}
                style={{ color: theme.palette.text.primary, textDecoration: "underline" }}
                state={{ type: "account", id: cellValues.row.AccountId }}
              >
                {cellValues.row.AccountId}
              </Link>
            );
          },
        },
        {
          field: "Timestamp",
          headerName: "Transaction Date",
          width: 160,
          display: "flex",
          valueFormatter: (params) => {
            return formatDateBasedOnLocale(params);
          },
        },

        {
          field: "location",
          headerName: "Location",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div>{params.row.Latitude + ", " + params.row.Longitude}</div>
            );
          },
        },
        {
          field: "GeoLookUpType",
          headerName: "Located By",
          width: 130,
          display: "flex",
          valueFormatter: (params) => {
            return getLocatedNameByCode(params);
          },
        },
        {
          field: "GeoReferenceId",
          headerName: "Transaction ID",
          width: 400,
          display: "flex",
        },
      ];
    } else {
      return [
        {
          field: "ReasonForFailure",
          headerName: "Status",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <div
                  style={{
                    minWidth: "8px",
                    minHeight: "8px",
                    backgroundColor: getColorCodeByReasonCode(
                      params.row.ReasonForFailure,
                      failedReasons
                    ),
                    borderRadius: "4px",
                  }}
                ></div>
                {getReasonsNameByCode(
                  params.row.ReasonForFailure,
                  failedReasons
                )}
              </div>
            );
          },
          valueGetter: (params) => {
            return getReasonsNameByCode(params, failedReasons);
          },
        },
        {
          field: "DeviceId",
          headerName: "Device ID",
          width: 250,
          display: "flex",
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/device/" + cellValues.row.DeviceId}
                style={{ color: theme.palette.text.primary, textDecoration: "underline" }}
                state={{ type: "device", id: cellValues.row.DeviceId }}
              >
                {cellValues.row.DeviceId}
              </Link>
            );
          },
        },
        {
          field: "Timestamp",
          headerName: "Transaction Date",
          width: 160,
          display: "flex",
          valueFormatter: (params) => {
            return formatDateBasedOnLocale(params);
          },
        },

        {
          field: "location",
          headerName: "Location",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div>{params.row.Latitude + ", " + params.row.Longitude}</div>
            );
          },
        },
        {
          field: "GeoLookUpType",
          headerName: "Located By",
          width: 130,
          display: "flex",
          valueFormatter: (params) => {
            return getLocatedNameByCode(params);
          },
        },
        {
          field: "GeoReferenceId",
          headerName: "Transaction ID",
          width: 400,
          display: "flex",
        },
      ];
    }
  } else {
    if (summaryType === "device") {
      return [
        {
          field: "AccountId",
          headerName: "Account ID",
          width: 250,
          display: "flex",
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/account/" + cellValues.row.AccountId}
                style={{ color: theme.palette.text.primary, textDecoration: "underline" }}
                state={{ type: "account", id: cellValues.row.AccountId }}
              >
                {cellValues.row.AccountId}
              </Link>
            );
          },
        },
        {
          field: "Timestamp",
          headerName: "Transaction Date",
          width: 160,
          display: "flex",
          valueFormatter: (params) => {
            return formatDateBasedOnLocale(params);
          },
        },
        {
          field: "ReasonForFailure",
          headerName: "Status",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <div
                  style={{
                    minWidth: "8px",
                    minHeight: "8px",
                    backgroundColor: getColorCodeByReasonCode(
                      params.row.ReasonForFailure,
                      failedReasons
                    ),
                    borderRadius: "4px",
                  }}
                ></div>
                {getReasonsNameByCode(
                  params.row.ReasonForFailure,
                  failedReasons
                )}
              </div>
            );
          },
          valueGetter: (params) => {
            return getReasonsNameByCode(params, failedReasons);
          },
        },
        {
          field: "location",
          headerName: "Location",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div>{params.row.Latitude + ", " + params.row.Longitude}</div>
            );
          },
        },
        {
          field: "GeoLookUpType",
          headerName: "Located By",
          width: 130,
          display: "flex",
          valueFormatter: (params) => {
            return getLocatedNameByCode(params);
          },
        },
        {
          field: "GeoReferenceId",
          headerName: "Transaction ID",
          width: 400,
          display: "flex",
        },
      ];
    } else {
      return [
        {
          field: "DeviceId",
          headerName: "Device ID",
          width: 250,
          display: "flex",
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/device/" + cellValues.row.DeviceId}
                style={{ color: theme.palette.text.primary, textDecoration: "underline" }}
                state={{ type: "device", id: cellValues.row.DeviceId }}
              >
                {cellValues.row.DeviceId}
              </Link>
            );
          },
        },
        {
          field: "Timestamp",
          headerName: "Transaction Date",
          width: 160,
          display: "flex",
          valueFormatter: (params) => {
            return formatDateBasedOnLocale(params);
          },
        },
        {
          field: "ReasonForFailure",
          headerName: "Status",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <div
                  style={{
                    minWidth: "8px",
                    minHeight: "8px",
                    backgroundColor: getColorCodeByReasonCode(
                      params.row.ReasonForFailure,
                      failedReasons
                    ),
                    borderRadius: "4px",
                  }}
                ></div>
                {getReasonsNameByCode(
                  params.row.ReasonForFailure,
                  failedReasons
                )}
              </div>
            );
          },
          valueGetter: (params) => {
            return getReasonsNameByCode(params, failedReasons);
          },
        },
        {
          field: "location",
          headerName: "Location",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div>{params.row.Latitude + ", " + params.row.Longitude}</div>
            );
          },
        },
        {
          field: "GeoLookUpType",
          headerName: "Located By",
          width: 130,
          display: "flex",
          valueFormatter: (params) => {
            return getLocatedNameByCode(params);
          },
        },
        {
          field: "GeoReferenceId",
          headerName: "Transaction ID",
          width: 400,
          display: "flex",
        },
      ];
    }
  }
};
