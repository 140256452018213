import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
  JWT_TOKEN,
  SELECTED_APP,
  SELECTED_DATE,
  SELECTED_FAILED_REASON,
  USER_INFO,
} from "../constants/Constants";
import { fetchListReasonsForFail } from "../services/HomeService";

const AppContext = createContext(null);
const { Provider } = AppContext;

const AppProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchParams, setSearchParams] = useState({
    basic: { searchText: "", selectedSearchType: "GeoReferenceId" },
    advance: null,
  });
  const [failedReasons, setFailedReasons] = useState(null);
  const [selectedUserApp, setSelectedUserApp] = useState(null);
  const [dashboardStates, setDashboardStates] = useState({});
  const setDashboardData = (key, value) => {
    setDashboardStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const [darkMode, setDarkMode] = useState(false);
  const [refreshSavedSearchSideMenu, setRefreshSavedSearchSideMenu] = useState(null);

  const clearAllStates = () => {
    setUserDetails(null);
    setSearchParams({
      basic: { searchText: "", selectedSearchType: "GeoReferenceId" },
      advance: null,
    });
    setFailedReasons(null);
    setSelectedUserApp(null);
    setDashboardStates({});
    setSelectedTab(0);
    localStorage.removeItem(SELECTED_APP);
    localStorage.removeItem(SELECTED_DATE);
    localStorage.removeItem(SELECTED_FAILED_REASON);
  };

  const getUserReportByAPI = (endpoint) => {
    const paths = endpoint.split("/");
    if (paths.length > 1) {
      const lastComponent = endpoint.substring(endpoint.lastIndexOf("/") + 1);

      if (
        userDetails &&
        userDetails.Data &&
        userDetails.Data.UserReports &&
        userDetails.Data.UserReports.length > 0
      ) {
        const report = userDetails.Data.UserReports.find(
          (item) => item.ReportApiName === lastComponent
        );
        return report;
      }
    }

    return null;
  };

  useEffect(() => {
    const token = Cookies.get(JWT_TOKEN);
    const userData = localStorage.getItem(USER_INFO);

    if (token && userData) {
      const user = {
        JWTid: token,
        Data: JSON.parse(userData),
      };
      setUserDetails(user);
      const userApp = localStorage.getItem(SELECTED_APP);
      if (userApp) {
        setDashboardData("user_apps", JSON.parse(userApp));
        setSelectedUserApp(JSON.parse(userApp));
      } else {
        setSelectedUserApp({
          id: user.Data.UserApps[0].AppId,
          label: user.Data.UserApps[0].AppName,
          ...user.Data.UserApps[0],
        });
      }
      const date = localStorage.getItem(SELECTED_DATE);
      if (date) {
        setDashboardData("date", JSON.parse(date));
      }

      const failed_reason = localStorage.getItem(SELECTED_FAILED_REASON);
      if (failed_reason) {
        setDashboardData("failed_reasons", JSON.parse(failed_reason));
      }

      fetchListReasonForFail(user);
    } else {
      setUserDetails(null);
    }
    setLoading(false);
  }, []);

  const fetchListReasonForFail = (user) => {
    const payload = {
      JWTid: user.JWTid,
    };

    fetchListReasonsForFail(payload)
      .then((response) => {
        if (response.Status === 0) {
          if (response.Data && response.Data.length > 0) {
            setFailedReasons(response.Data);
          }
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  return (
    <Provider
      value={{
        userDetails,
        setUserDetails,
        drawerOpen,
        setDrawerOpen,
        loading,
        selectedTab,
        setSelectedTab,
        searchParams,
        setSearchParams,
        failedReasons,
        setFailedReasons,
        selectedUserApp,
        setSelectedUserApp,
        dashboardStates,
        setDashboardData,
        clearAllStates,
        getUserReportByAPI,
        darkMode,
        setDarkMode,
        refreshSavedSearchSideMenu,
        setRefreshSavedSearchSideMenu
      }}
    >
      {children}
    </Provider>
  );
};

export { AppContext, AppProvider };
