import React, { useState, useEffect } from "react";
import {
  DataGrid,
  gridClasses,
} from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";
import "./DashboardListViewStyle.scss";
import { formatDateBasedOnLocale } from "../../../utils/DateUtils";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import CommonListView from "../../commonListView/CommonListView";

const ODD_OPACITY = 0.2;

const DashboardListView = (props) => {
  const {
    rows,
    columns,
    getRowId,
    data,
    onRefreshData,
    type,
    reasonsForFail,
    showActivity,
    selectedPage,
  } = props;
  const [paginationModel, setPaginationModel] = useState({
    page: selectedPage ? selectedPage.page - 1 : 0,
    pageSize: data.PageSize,
  });

  const [activity, setActivity] = useState(false);

  useEffect(() => {
    setActivity(showActivity);
  }, [showActivity]);

  const getNameByReasonCode = (reasonCode) => {
    if (!reasonsForFail) return reasonCode;

    const itemFound = reasonsForFail.find(
      (reasonItem) => reasonItem.id === reasonCode
    );

    return itemFound?.label;
  };

  const getColorCodeByReasonCode = (reasonCode) => {
    if (!reasonsForFail) return null;

    const itemFound = reasonsForFail.find(
      (reasonItem) => reasonItem.id === reasonCode
    );
    return itemFound ? itemFound.color : "#9dc5ed";
  };

  const getColumns = () => {
    if (activity) {
      return [
        {
          field: "ReasonForFailure",
          headerName: "Status",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "7px",
                }}
              >
                <div
                  style={{
                    minWidth: "8px",
                    minHeight: "8px",
                    backgroundColor: getColorCodeByReasonCode(
                      params.row.ReasonForFailure
                    ),
                    borderRadius: "4px",
                  }}
                ></div>
                {getNameByReasonCode(params.row.ReasonForFailure)}
              </div>
            );
          },
          valueGetter: (params) => {
            return getNameByReasonCode(params);
          },
        },
        {
          field: "AccountId",
          headerName: "Account ID",
          width: 180,
          display: "flex",
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/account/" + cellValues.row.AccountId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
                state={{ type: "account", id: cellValues.row.AccountId }}
              >
                {cellValues.row.AccountId}
              </Link>
            );
          },
        },
        {
          field: "DeviceId",
          headerName: "Device ID",
          width: 380,
          display: "flex",
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/device/" + cellValues.row.DeviceId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
                state={{ type: "device", id: cellValues.row.DeviceId }}
              >
                {cellValues.row.DeviceId}
              </Link>
            );
          },
        },
        {
          field: "Timestamp",
          headerName: "Date & Time",
          width: 180,
          display: "flex",
          valueFormatter: (params) => {
            return formatDateBasedOnLocale(params);
          },
        },
        {
          field: "GeoReferenceId",
          headerName: "Transaction ID",
          width: 400,
          display: "flex",
        },
        {
          field: "location",
          headerName: "Location",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div>{params.row.Latitude + ", " + params.row.Longitude}</div>
            );
          },
        },
      ];
    } else {
      return [
        {
          field: "AccountId",
          headerName: "Account ID",
          width: 180,
          display: "flex",
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/account/" + cellValues.row.AccountId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
                state={{ type: "account", id: cellValues.row.AccountId }}
              >
                {cellValues.row.AccountId}
              </Link>
            );
          },
        },
        {
          field: "DeviceId",
          headerName: "Device ID",
          width: 380,
          display: "flex",
          renderCell: (cellValues) => {
            return (
              <Link
                to={"/summary/device/" + cellValues.row.DeviceId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
                state={{ type: "device", id: cellValues.row.DeviceId }}
              >
                {cellValues.row.DeviceId}
              </Link>
            );
          },
        },
        {
          field: "Timestamp",
          headerName: "Date & Time",
          width: 180,
          display: "flex",
          valueFormatter: (params) => {
            return formatDateBasedOnLocale(params);
          },
        },
        {
          field: "ReasonForFailure",
          headerName: "Status",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "7px",
                  // backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
                  // color: theme.palette.text.primary
                }}
              >
                <div
                  style={{
                    minWidth: "8px",
                    minHeight: "8px",
                    backgroundColor: getColorCodeByReasonCode(
                      params.row.ReasonForFailure
                    ),
                    borderRadius: "4px",
                  }}
                ></div>
                {getNameByReasonCode(params.row.ReasonForFailure)}
              </div>
            );
          },
          valueGetter: (params) => {
            return getNameByReasonCode(params);
          },
        },
        {
          field: "GeoReferenceId",
          headerName: "Transaction ID",
          width: 400,
          display: "flex",
          backgroundColor:
            theme.palette.mode === "dark" ? "#1c1c1c" : "#d8d8d8",
          color: theme.palette.text.primary,
        },
        {
          field: "location",
          headerName: "Location",
          width: 200,
          display: "flex",
          renderCell: (params) => {
            return (
              <div>{params.row.Latitude + ", " + params.row.Longitude}</div>
            );
          },
        },
        {
          field: "ReasonForLookup",
          headerName: "Reason for Check",
          width: 200,
          display: "flex",
        },
      ];
    }
  };

  const theme = useTheme();

  return (
    <div
      style={{
        height: "55vh",
        width: "100%",
        backgroundColor: theme.palette.mode === "dark" ? "#1c1c1c" : "#d8d8d8",
        color: theme.palette.text.primary,
      }}
    >
      <CommonListView
        rows={data.Results}
        columns={getColumns()}
        rowCount={data.TotalRecords}
        pageSize={100}
        onRefreshData={(newPaginationModel) => {
          setPaginationModel(newPaginationModel);
          if (onRefreshData) {
            onRefreshData(
              { ...newPaginationModel, page: newPaginationModel.page },
              type
            );
          }
        }
        }
        getRowId={(row) => row.GeoReferenceId}
        toolbar={null}
        // slots={{ toolbar: () => <CustomToolbar onPrint={handlePrint} /> }}
        // onRowSelectionModelChange={handleSelectionModelChange} // Track selection
        pageSizeOptions={[100]}
        reasonsForFail={reasonsForFail}
      />
    </div>
  );
};

export default DashboardListView;
