import { Box, IconButton, Typography } from "@mui/material";
import { getReasonsNameByCode } from "../../utils/CommonUtils";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";
import { CopyAllRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import "./ACTTransactionsMultipleStyles.scss";

const ACTTransactionsMultipleAllList = ({
  failedReasons,
  onCopyData,
  moreItems,
  latLngGroup,
}) => {
  const theme = useTheme();
  const customtextColorForDarkmode = (theme) => ({
    color: theme.palette.mode === "dark" && "#ffffff",
  });

  return (
    <>
      {moreItems &&
        moreItems.map((result) => {
          return (
            <Box
              sx={{
                padding: "0.5rem 0.25rem 0.5rem 0.5rem",
                maxWidth: "31.5rem",
                maxHeight: "21rem",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
                "&:nth-of-type(odd)": {
                  backgroundColor: "none",
                },
                "&:nth-of-type(even)": {
                  backgroundColor: theme.palette.mode === "light" && "#ececec",
                  borderTop: "0.1rem solid #ececec",
                  borderBottom: "0.1rem solid #ececec",
                },
              }}
              style={customtextColorForDarkmode(theme)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "1.2rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.8125rem",
                    lineHeight: "1",
                  }}
                  style={customtextColorForDarkmode(theme)}
                >
                  <strong>Date & Time:</strong>{" "}
                  {formatDateBasedOnLocale(result.Timestamp)}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "1.2rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.8125rem",
                    lineHeight: "1",
                  }}
                  style={customtextColorForDarkmode(theme)}
                >
                  <strong>Device ID: </strong>
                  <Link
                    to={"/summary/device/" + result.DeviceId}
                    style={{
                      color: theme.palette.text.primary,
                      textDecoration: "underline",
                    }}
                  >
                    {result.DeviceId}
                  </Link>
                </Typography>
                <div>
                  <IconButton
                    aria-label="copy"
                    size="small"
                    onClick={() => onCopyData(result.DeviceId)}
                  >
                    <CopyAllRounded
                      sx={{
                        fontSize: "1.3rem",
                      }}
                      style={customtextColorForDarkmode(theme)}
                    />
                  </IconButton>
                </div>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "1.2rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.8125rem",
                    lineHeight: "1",
                  }}
                  style={customtextColorForDarkmode(theme)}
                >
                  <strong>Account ID: </strong>
                  <Link
                    to={"/summary/account/" + result.AccountId}
                    style={{
                      color: theme.palette.text.primary,
                      textDecoration: "underline",
                    }}
                  >
                    {result.AccountId}
                  </Link>
                </Typography>
                <div>
                  <IconButton
                    aria-label="copy"
                    size="small"
                    onClick={() => onCopyData(result.AccountId)}
                  >
                    <CopyAllRounded
                      sx={{
                        fontSize: "1.3rem",
                      }}
                      style={customtextColorForDarkmode(theme)}
                    />
                  </IconButton>
                </div>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "1.2rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.8125rem",
                    lineHeight: "1",
                  }}
                  style={customtextColorForDarkmode(theme)}
                >
                  <strong>Status: </strong>{" "}
                  {getReasonsNameByCode(result.ReasonForFailure, failedReasons)}{" "}
                </Typography>
                <br></br>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "1.2rem",
                }}
              >
                <Link
                  to={"/summary/device/" + result.DeviceId}
                  state={{ type: "device", id: result.DeviceId }}
                >
                  {"view all device activity"}
                </Link>
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default ACTTransactionsMultipleAllList;
