import React, { useContext } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import { AppContext } from "../../context/AppContext";
import {
  getReasonsNameByCode,
  getLocatedNameByCode,
  getReasonForLookupNameByCode,
} from "../../utils/CommonUtils";

import { useTheme } from '@mui/material/styles';

const MostPopularComponent = (props) => {
  const { label, value } = props;
  const theme = useTheme();
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: "400",
          color: theme.palette.text.primary,
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: "300",
          color: theme.palette.text.primary,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

const SummaryMostPopular = (props) => {
  const { data, summaryType } = props;
  const appContext = useContext(AppContext);
const theme = useTheme();
  return (
    <Card>
      <CardHeader
        title={
          <Typography
            variant="body1"
            component="div"
            sx={{
              fontSize: "1rem",
              fontWeight: "500",
            }}
          >
            {"Most Popular"}
          </Typography>
        }
      />
      <CardContent>
        <Grid
          sx={{ flexGrow: 1, justifyContent: "space-between" }}
          container
          spacing={{ xs: 2, md: 5 }}
          columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
        >
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <MostPopularComponent
              label={summaryType === "device" ? "Account:" : "Device"}
              value={
                data
                  ? summaryType === "device"
                    ? data.AccountId
                    : data.Device
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <MostPopularComponent
              label="Location:"
              value={data ? data.Latitude + ", " + data.Longitude : ""}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={2}>
            {" "}
            <MostPopularComponent
              label="Indentifier:"
              value={data ? getLocatedNameByCode(data.LocatedBy) : ""}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={4} lg={2}>
            <MostPopularComponent
              label="Check:"
              value={
                data ? getReasonForLookupNameByCode(data.ReasonForLookup) : ""
              }
            />
          </Grid>

          <Grid item xs={6} sm={4} md={4} lg={2}>
            <MostPopularComponent
              label="Status:"
              value={
                data
                  ? getReasonsNameByCode(
                      data.ReasonForFailure,
                      appContext.failedReasons
                    )
                  : ""
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SummaryMostPopular;
