import { CopyAllRounded } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";
import { getColorCodeByReasonCode, getReasonsNameByCode } from "../../utils/CommonUtils";

const ACTPopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  moreItems,
  onCopyData,
  failedReasons,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div style={{ display: "flex", margin: "1rem", flexDirection: "column" }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date & Time</TableCell>
                <TableCell>Device ID</TableCell>
                <TableCell>Account ID</TableCell>
                <TableCell>Lat & Lng</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {moreItems.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 300, fontSize: "small" }}
                  >
                    {formatDateBasedOnLocale(item.Timestamp)}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 300, fontSize: "small" }}
                  >
                    <div className="cluster-cell-item">
                      <Link
                        key={index}
                        to={"/summary/device/" + item.DeviceId}
                        style={{
                          color: "#000000",
                          textDecoration: "underline",
                        }}
                        state={{ type: "device", id: item.DeviceId }}
                      >
                        {item.DeviceId}
                      </Link>
                      <IconButton
                        aria-label="copy"
                        size="small"
                        onClick={() => onCopyData(item.DeviceId)}
                      >
                        <CopyAllRounded className="copy-icon" />
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell sx={{ fontWeight: 300, fontSize: "small" }}>
                    <div className="cluster-cell-item">
                      <div> {item.AccountId}</div>
                      <IconButton
                        aria-label="copy"
                        size="small"
                        onClick={() => onCopyData(item.AccountId)}
                      >
                        <CopyAllRounded className="copy-icon" />
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell sx={{ fontWeight: 300, fontSize: "small" }}>
                    <div className="cluster-cell-item">
                      <div>
                        {item.Latitude},{item.Longitude}
                      </div>
                      <IconButton
                        aria-label="copy"
                        size="small"
                        onClick={() =>
                          onCopyData(item.Latitude + "," + item.Longitude)
                        }
                      >
                        <CopyAllRounded className="copy-icon" />
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell sx={{ fontWeight: 300, fontSize: "small" }}>
                    <div className="cluster-cell-item">
                      <div>{item.GeoReferenceId}</div>
                      <IconButton
                        aria-label="copy"
                        size="small"
                        onClick={() => onCopyData(item.GeoReferenceId)}
                      >
                        <CopyAllRounded className="copy-icon" />
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell sx={{ fontWeight: 300, fontSize: "small" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          backgroundColor: getColorCodeByReasonCode(
                            item.ReasonForFailure,
                            failedReasons
                          ),
                          borderRadius: "4px",
                        }}
                      ></div>
                      <div>
                        {getReasonsNameByCode(
                          item.ReasonForFailure,
                          failedReasons
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Popover>
  );
};

export default ACTPopover;
